@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: $base_width;
  max-width: $base_width_max;
  margin-left: auto;
  margin-right: auto;
  &.-full {
    width: $base_width_max;
  }
  @include mq(ss){
    max-width: 90%;
  }
}

.c-totop{
  position: fixed;
  right: 1em;
  bottom:1em;
  z-index: 10;
  a{
    background: $main_color;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    position: relative;
    svg{
      fill:#fff;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
    }
  }
}


section.js-anime{
  opacity: 0;
  transform: translateY(4rem);
  transition-duration: 0.7s;
  &.is-anime{
    opacity: 1;
    transform: translateY(0rem);
  }
}

/*-------------------------------
c-price
-------------------------------*/
.c-price{
  &__box{
    background: #fff;
    padding:4em;
    @include mq(xs-){
      display: flex;
      align-items:center;
      justify-content: space-between;
    }
    &:nth-child(odd){
      flex-direction: row-reverse;
    }
    &:nth-child(n+2){
      margin-top: 3em;
    }
    @include mq(sm){
      padding:3em;
    }
    @include mq(xs){
      padding:1.8em;
    }
    &_img{
      width: 40%;
      img{
        max-width: 100%;
      }
      @include mq(xs){
        width: 100%;
        margin-bottom: 2em;
      }
    }
    &_txt{
      width: 50%;
      .comment{
        span{
          font-size: 2rem;
          display: block;
        }
      }
      @include mq(xs){
        width: 100%;
        .c-ttl01{
          margin-bottom: 0.8em;
        }
        .comment{
          span{
            font-size: 1.8rem;
            margin-bottom: 0.3em;
          }
          p{
            font-size: 1.4rem;
          }
        }
      }
    }
    .price_list{
      li{
        padding:1em;
        border-bottom:1px dashed #ddd;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
/*-------------------------------

-------------------------------*/
.c-navi{
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  padding-top:12rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 2;
  @include mq(sm){
    padding-top:8rem;
  }
  @include mq(xs){
    padding-top:6rem;
  }
  li{
    line-height: 1;
    width: 33%;
    text-align: center;
    margin-right: 0.5%;
    &:nth-child(n+4){
      margin-top: 0.5em;
    }
    @include mq(xs-){
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    a{
      padding:1em;
      background:$main_color;
      color: $sub_color;
      display: block;
      border-radius:5px;
      @include mq(sm-){
        &:before{
        @include icon('pad');
          opacity: 0;
          transition-duration: 0.3s;
          margin-right: 0.3em;
        }
        &:hover{
          &:before{
            opacity: 1;
          }
        }
      }
    }
    @include mq(xs){
      width: 49%;
      &:nth-child(n+3){
        margin-top: 0.5em;
      }
      &:nth-child(2n){
        margin-right: 0;
      }
      a{
        padding:1em 0.3em;
        font-size: 1.4rem;
      }
    }
  }
}


/*-------------------------------
  under > key
-------------------------------*/
.p-under_key{
  position: relative;
  &__img.c-img_ofi{
    padding-top: 30%;
    position: relative;
    &:before{
      content: "";
      background: rgba(45,34,34,0.4);
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top:0;
      z-index: 2;
    }
    @include mq(xs){
      padding-top: 40%;
    }
  }
  &__txt{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50% , -50%);
    z-index: 3;
    text-align: center;
    width: 100%;
    span{
      font-size: 4rem;
      line-height: 1;
      color: $sub_color;
      font-family: $base_font_en;
      margin-bottom: 0.3em;
      display: block;
    }
    h1{
      font-size: 4.4rem;
      color: #fff;
      font-weight: 400;
    }
    @include mq(sm){
      span{
        font-size: 2.4rem;
      }
      h1{
        font-size: 3rem;
      }
    }
    @include mq(xs){
      span{
        font-size: 2rem;
      }
      h1{
        font-size: 2.4rem;
      }
    }
  }
}

/*--------------------------------------------
  TO TOP
--------------------------------------------*/
.totop{

}


.bg{
  background: $main_color;
  position: relative;
  color: #fff;
  padding: 20rem 0;
  @include mq(sm){
    padding: 15rem 0;
  }
  @include mq(xs){
    padding: 10rem 0;
  }
  &:before,
  &:after{
    content: "";
    background: url('/assets/img/common/c-line.png') repeat-x;
    background-size: contain;
    width: 100%;
    height: 52px;
    display: block;
    position: absolute;
    left: 0;
    @include mq(sm){
      height: 30px;
    }
  }
  &:before{
    top:0;
  }
  &:after{
    bottom:0;
  }

}

.c-bnr{
  padding-top:20rem;
  width: 700px;
  @include mq(sm){
    padding-top:15rem;
  }
  @include mq(xs){
    padding-top:10rem;
  }
  a{
    background: $main_color;
    display: flex;
    align-items: center;
    @include mq(sm-){
      &:hover{
        opacity: 0.6;
      }
    }
  }
  &__txt{
    width: 43%;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    @include mq(xs){
      font-size: 1.6rem;
    }
  }
  &__img.c-img_ofi{
    width: 57%;
    padding-top: 22.5%;
  }
}

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl01{
  margin-bottom: 2.8em;
  span{
    display: block;
    font-size: 2.4rem;
    line-height: 1;
    font-family: $base_font_en;
    color: $sub_color;
    margin-bottom: 0.3em;
  }
  h2{
    font-size: 4rem;
    line-height: 1;
    font-weight:400;
  }
  @include mq(md){
    span{
      font-size: 2rem;
    }
    h2{
      font-size: 3.8rem;
    }
  }
  @include mq(sm){
    margin-bottom: 2em;
    span{
      font-size: 1.8rem;
    }
    h2{
      font-size: 3.4rem;
    }
  }
  @include mq(xs){
    margin-bottom: 2em;
    span{
      font-size: 1.6rem;
    }
    h2{
      font-size: 2.2rem;
    }
  }
}
.c-ttl02{
  display: flex;
  align-items:center;
  span{
    font-size: 2.4rem;
    line-height: 1;
    font-family: $base_font_en;
    color: $sub_color;
    position: relative;
    display: block;
    &:before,
    &:after{
      content: "";
      background:$sub_color;
      display: block;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
    }
    &:before{
      width: 1.8em;
      height: 1px;
      right: -5.5rem;
    }
    &:after{
      right: -6rem;
      width: 5px;
      height: 5px;
      border-radius:50%;
    }
  }
  h2{
    font-size: 3.3rem;
    line-height: 1.3;
    font-weight: 400;
    padding-left: 8rem;
  }
  @include mq(md){
    span{
      font-size: 2rem;
    }
    h2{
      font-size: 2.8rem;
    }
  }
  @include mq(sm){
    span{
      font-size: 1.8rem;
      &:before,&:after{
        display: none;
      }
    }
    h2{
      font-size: 2.4rem;
      padding-left: 3rem;
    }
  }
}


.c-btn01{
  background:#fff;
  width: 210px;
  max-width: 100%;
 a{
    font-size: 1.4rem;
    padding: 0.8em 1em;
    display: block;
    position: relative;
    border:1px solid #707070;
    &:before,
    &:after{
      content: "";
      display: block;
      position: absolute;
      background: #707070;
      right: -8%;
      transition-duration: 0.3s;
    }
    &:before{
      width: 50px;
      height: 1px;
      top:50%;
    }
    &:after{
      width: 8px;
      height: 1px;
      top:45%;
      transform: rotate(45deg);
    }
    @include mq(sm-){
      &:hover{
        background: $main_color;
        color: #fff;
        &:before,
        &:after{
          right: -12%;
        }
      }
    }
  }
}

.c-btn02{
  font-size: 1.4rem;
  position: relative;
  padding-right:1.5em;
  &:before,
  &:after{
    content: "";
    display: block;
    position: absolute;
    background: #707070;
    right: -2%;
    transition-duration: 0.3s;
  }
  &:before{
    width: 23px;
    height: 1px;
    top:50%;
  }
  &:after{
    width: 8px;
    height: 1px;
    top:30%;
    transform: rotate(45deg);
  }
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  &.-fx{
    display: flex;
    justify-content: center;
    align-items:center;
    &.-justify{
      justify-content: space-between;
    }
    &.-right{
      justify-content: flex-end;
    }
  }
  &.-center{
    text-align: center;
  }
  &.-right{
    text-align: right;
  }
}




/*--------------------------------------------
  TABLE
--------------------------------------------*/

.submit_area{
  text-align: center;
  input[type=submit]{
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding:1em;
    transition-duration: 0.3s;
  }
  .btn_submit{
    max-width: 30rem;
    width: 100%;
    border:2px solid $accent_color;
    background: $accent_color;
    margin: auto;
    @include mq(sm-){
      &:hover{
        color: $accent_color;
        background: #fff;
      }
    }
  }
  .back{
    background: #777;
    max-width: 30rem;
    width: 100%;
    border:2px solid #777;
    margin: auto 0;
    @include mq(sm-){
      &:hover{
        color: #777;
        background: #fff;
      }
    }
    @include mq(xs){
      margin: 1rem auto 0!important;
    }
  }
}

.c-tbl{
  line-height: 1;
  @include mq(xs-){
    tr:nth-child(even){
      background: #F5F5F5;
    }
  }
  &.-center{
    tr{
      @include mq(xs-){
        th:nth-child(1){
          width: 30%;
          @include mq(sm-){
            padding-left: 5em;
          }
        }
        th:nth-child(2){
          width: 44%;
        }
        td{
          width: 26%;
        }
      }
    }
    @include mq(xs){
      tr{
        th:nth-child(2){
          background: #fff;
          padding-bottom: 0;
        }
      }
      th,td{
        width: 100%;
      }
    }
  }
  th,td{
    padding: 1.5em;
  }
  th{
    &:before{
      @include icon('pad');
      margin-right: 0.4em;
    }
    width: 30%;
    font-size: 1.1em;
    text-align: left;
    line-height: 1.4;
    @include mq(sm){
      width: 40%;
    }
  }
  td{
    width: 70%;
    line-height: 1.9;
    @include mq(sm){
      width: 60%;
    }
    span{
      font-weight: 600;
    }
    a{
      text-decoration: underline;
      color: $main_color;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .ovv{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
    li{
      padding-left: 1em;
      position: relative;
      &:before{
        content: "";
        background: $main_color;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 0;
        top:0.7em;
      }
    }
  }
  @include mq(xs){
    tr,th,td{
      display: block;
      width: 100%;
    }
    th{
      padding: 1em;
      background: #F5F5F5;
    }
    td{
      padding: 1em 1em 2em;
    }
  }
}

.c-tbl_responsive{
  @include mq(sm){
    &__inner{
      overflow-x:auto;
      overflow-y: visible;
      border: 1px solid #ececec;
      table{
        min-width: 100%;
        width:auto;
        th,
        td{
          white-space: nowrap;
        }
      }
      &::-webkit-scrollbar {
        height: .6rem;
      }
      &::-webkit-scrollbar-track {
      background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: $main_color;
      }
    }
    &:after{
      display: block;
      content:"横にスクロールできます";
      text-align: center;
      margin-top: .5em;
    }
  }
}

/*--------------------------------------------
 BLOCK - NEWS
--------------------------------------------*/


/*--------------------------------------------
  c-contents
--------------------------------------------*/
.c-contents{
  margin-bottom: 6em;
  @include mq(xs){
    margin-bottom: 3em;
  }
  &:last-child{
    margin-bottom: 0;
  }
  .c-inner{
    background: #fff;
    padding: 5.5em;
    @include mq(sm){
      padding: 3em 2em;
    }
  }
  &__box{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
    &_list{
      width: 62%;
      li{
        border-bottom:1px dashed #ddd;
        padding: 1em 0 1em 2.2em;
        position: relative;
        &:first-child{
          padding-top: 0;
          &:before{
            top:0.1em;
          }
        }
        &:before{
          @include icon('pad2');
          color: #000;
          font-size: 1.5em;
          position: absolute;
          left: 0;
          top:0.7em;
        }
      }
      @include mq(sm){
        font-size: 1.4rem;
      }
      @include mq(xs){
        width: 100%;
        margin-bottom: 2em;
      }
    }
    &_img.c-img_ofi{
      width: 34%;
      padding-top: 47%;
      @include mq(xs){
        width: 100%;
        padding-top: 105%;
      }
    }
  }
}

/*--------------------------------------------
 BLOCK - FORM
--------------------------------------------*/

.c-form{
  &_box{
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(xs){
      padding-top:8rem;
    }
    .c-inner{
      background: #fff;
      padding: 4em;
      @include mq(sm){
        padding:3rem;
      }
      @include mq(xs){
        padding:2rem;
      }
    }
    .postal{
      display: flex;
      align-items: center;
      span{
        margin-top: 0em;
        margin-bottom: 0em;
        margin-right: 1em;
      }
    }
  }
  $amp :&;
  .read{
    text-align: center;
    &.cnfm{
      font-size: 1.2em;
      text-align: center;
      background: $sub_color;
      padding: 1em 0
    }
  }
  @include mq(xs){
    .read{
      text-align: left;
    }
  }
  &__sheet{
    @include mq(sm-){
      margin-bottom: $margin_md;
    }
    tr{
      border-bottom:solid 1px #ddd;
      &:last-child{
        border:none;
      }
      &.-top{
        th,
        td{
          vertical-align: top;
        }
        #{$amp}__label{
          padding-top: .5em;
        }
      }
    }
    th,
    td{
      padding: 2em;
      vertical-align: middle;
      text-align: left;
      line-height: 1.6;
      font-weight: 400;
    }
    th{
      width: 30%;
      span{
        font-size: 1em;
        margin-left: 0.5em;
        color: $accent_color;
        &:before{
          @include icon('pad');
          margin-right: 0.5em;
        }
      }
    }
    td{
      width: 70%;
      .h-adr{
        >div:nth-child(2){
          span{
            margin-top: 0;
          }
        }
      }
      span{
        margin-top: 1em;
        margin-bottom: 0.5em;
        display: block;
      }
    }
    @include mq(sm){
      .input_short{
        width: 50%;
      }
      .input_middle{
        width: 80%;
      }
      .input_long{
        width: 100%;   
      }
      tr{
        display: block;
        padding: 1.75em 0;
      }
      th,
      td{
        display: block;
        padding: 0;
        width: 100%;
      }
      th{
        line-height: 1;
        margin-bottom: 1.5em;
      }
    }
    @include mq(xs){
      .input_short{
        width: 60%;
      }
      .input_middle{
        width: 100%;
      }
    }
  }
  &__label{
    display: flex;
    align-items: center;
    &.-required{
      &:after{
        content:"【必須】";
        color: #B70000;
        margin-left: .5em;
        font-size: 1.3rem;
        @include mq(sm){
          @include mq(xs-){
            display: block;
            font-size: .875em;
            margin-left: 0;
          }
        }
      }
    }
    @include mq(md){
      @include mq(sm-){
        display: block;
        &.-require{
          &:after{
            display: block;
            margin-left: 0;
            text-indent:-.5em;
          }
        }
      }
    }
  }
  #{$amp}__error{
    margin-top: .5em;
    .error{
      color: $font_color;
      font-size: 1em;
      &:before{
        content:"※";
        margin-right: .25em;
      }
    }
  }
  &__submit{
    @include mq(sm-){
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    background: $main_color;
    color: #fff;
    text-align: center;
    padding: 1em 2em;
    @include mq(xs-){
      width: 300px;
      &.-back{
        margin-left: 1em;
        background: #828282;
      }
      .c-btn_pl{
        width: 300px;
        &:nth-child(n+2){
          margin-right: $margin_ss;
        }
      }
    }
    @include mq(sm){
      justify-content: center;
      margin-top: $margin_sm;
      flex-direction: row;
    }
    @include mq(xs){
      flex-direction: row;
      width: 100%;
      .c-btn_pl{
        display: block;
        margin:0 auto;
        width: 48%;
        &:nth-child(n+2){
          margin-left: 4%;
          margin-right: 0;
        }
      }
    }
    @include mq(ss){
      display: block;
      .c-btn_pl{
        width: 100%;
        &:nth-child(n+2){
          margin-left: 0;
          margin-top: $margin_sm;
        }
      }
    }
  }
  &__agreement{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a{
      text-decoration:underline;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
    #{$amp}__input{
      &.-check{
        padding: 0;
        margin-right: .75em;
        .mwform-checkbox-field{
          display: block;
          margin-top: 0;
          &:not(:last-child){
            margin-right: 0;
          }
        }
        input[type="checkbox"]{
          + .mwform-checkbox-field-text{
            padding-left: 1.75em;
            font-size: 0;
          }
        }
      }
    }
    #{$amp}__error{
      @include mq(sm-){
        margin-top: 0;
        margin-left: 1em;
      }
      @include mq(sm-){
        width: 100%;
        margin-top: 1em;
        margin-left: 0;
      }
    }
  }
  &__input{
    textarea{
      height: 12em;
    }
    @include mq(sm-){
      &.-short{
        width: 40%;
      }
      &.-middle{
        width: 60%;
      }
      &.-long{
        width: 80%;
      }
    }
    &.-radio{
      line-height: 1;
      padding: .5em 0;
      @include mq(ss-){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-radio-field{
        display: block;
        margin-top: 1em;
        &:not(:last-child){
          margin-right:2.5em;
        }
        +.mwform-radio-field{
          margin-left: 0;
        }
      }
      input[type="radio"]{
        display: none;
        + .mwform-radio-field-text{
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after{
            position: absolute;
            content:"";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
          }
          &:before{
            border:solid 1px #aaa;
          }
          &:after{
            background: $font_color;
            transform:scale(.35);
            visibility: hidden;
          }
        }
        &:checked{
          + .mwform-radio-field-text{
            &:after{
              visibility: visible;
            }
          }
        }
      }
    }
    &.-check{
      line-height: 1;
      padding: .5em 0;
      @include mq(ss-){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-checkbox-field{
        display: block;
        margin-top: 1.6rem;
        &:not(:last-child){
          margin-right:4rem;
        }
        ~ .mwform-checkbox-field{
          margin-left: 0;
        }
      }
      input[type="checkbox"]{
        display: none;
        + .mwform-checkbox-field-text{
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after{
            position: absolute;
            content:"";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
          }
          &:before{
            border:solid 2px #e0e0e0;
          }
          &:after{
            height: 1rem;
            width: 1.6rem;
            border-left: solid 3px $font_color;
            border-bottom: solid 3px $font_color;
            transform-origin: left bottom;
            transform:rotate(-45deg) translate(25%,75%);
            visibility: hidden;
          }
        }
        &:checked{
          + .mwform-checkbox-field-text{
            &:after{
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .mw_wp_form_preview{
    #{$amp}__label{
      padding: 0;
    }
    #{$amp}__agreement{
      display: none;
    }
    #{$amp}__sheet{
      tr{
        &.-top{
          th,
          td{
            vertical-align: middle;
          }
          #{$amp}__label{
            padding-top: 0;
          }
        }
      }
    }
    #{$amp}__submit{
      justify-content: flex-end;
    }
  }
  &.-thanks{
    .c-btnwrap{
      margin-top: $margin_md;
    }
    @include mq(xs){
      .c-ttl_pl{
        font-size: 2rem;
      }
      .c-btnwrap{
        margin-top: $margin_sm;
        text-align: center;
      }
    }
  }
}

.p-thanks{
  p{
    text-align: center;
    margin-bottom: 2em;
  }
  .c-form__btn{
    margin: auto;
  }
  @include mq(xs){
    p{
      text-align: left;
      font-size: 1.4rem;
      line-height: 2;
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-ta_justify{
  @include mq(ss-){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}


.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}