@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key{
  position: relative;
  &__logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    img{
      max-width: 434px;
    }
    @include mq(md){
      img{
        max-width: 300px;
      }
    }
    @include mq(xs){
      left: 50%;
      transform: translate(-50%,-50%);
      img{
        max-width: 280px;
      }
    }
  }
  &__slide{
    img{
      max-width: 100%;
    }
    &.slick-dotted.slick-slider{
      margin-bottom: 0;
    }
    .slick-dots{
      bottom: 1em;
      li{
        margin: 0 1em;
        button{
          &:before{
            @include icon('pad');
            font-size: 20px;
          }
        }
      }
    }
    @include mq(sm){
      .slick-slide{
        height: 42rem;
      }
      img{
        object-fit: cover;
        object-position: right;
        width: 100%;
        height: 100%;
      }
    }
    @include mq(xs){
      .slick-slide{
        height: 50rem;
      }
    }
  }
}

.p-idx_concept{
  overflow: hidden;
  .c-inner{
    position: relative;
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
    }
  }
  &__news{
    width: 1200px;
    max-width: 92%;
    margin: 0 auto 5em;
    &_list{
      @include mq(xs-){
        display: flex;
        align-items:stretch;
      }
      li{
        width: 33%;
        a{
          display: block;
          @include mq(sm-){
            display: flex;
            align-items: baseline;
          }
          background: #fff;
          padding:1em 2.5em 1em 1em;
          height: 100%;
          border: 1px solid #fff;
          time{
            margin-right: 1em;
            font-weight: 500;
          }
          p{
            line-height: 1.6;
          }
          position: relative;
          &:before{
            @include icon('chevron-right');
            color: $main_color;
            position: absolute;
            right: 1em;
            top:50%;
            transform: translateY(-50%);
            transition-duration: 0.3s;
          }
          @include mq(sm-){
            &:hover{
              background:$main_color;
              color: #fff;
              &:before{
                color: #fff;
              }
            }
          }
        }
        @include mq(xs-){
          &:nth-child(n+2){
            margin-left: 0.5%;
          }
        }
      }
      @include mq(xs){
        li{
          width: 100%;
          &:nth-child(n+2){
            margin-top: 0.3em;
          }
        }
      }
    }
  }
  &__img{
    width: 47.5%;
    max-height: 80rem;
    position: relative;
    div{
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100%;
      img{
        height: 100%;
      }
    }
    @include mq(sm){
      width: 100%;
      div{
        position: static;
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
  }
  &__txt{
    width: 50%;
    img{
      max-width: 100%;
    }
    &_read{
      margin-bottom: 2.7em;
      p{
        &:nth-child(n+2){
          margin-top: 1.9em;
        }
      }
    }
    .c-btn01{
      margin-bottom: 3.1em;
    }
    &_img{
      display: flex;
      justify-content: space-between;
      align-items: center;
      li{
        max-width: 48%;
      }
    }
    @include mq(sm){
      width: 100%;
      margin-bottom: 1.5em;
    }
  }
}

.p-idx_cont{
  padding:18rem 0;
  position: relative;
  overflow: hidden;
  @include mq(sm){
    padding:15rem 0;
  }
  &:before{
    content: "";
    background:url('/assets/img/common/c-leaf.png') no-repeat;
    background-size:cover;
    width: 580px;
    height: 600px;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    @include mq(sm){
      width: 380px;
      height: 400px;
    }
    @include mq(xs){
      width: 180px;
      height: 200px;
    }
  }
  // &:after{
  //   content: "";
  //   background:url('/assets/img/common/c-pad.png') no-repeat;
  //   background-size:cover;
  //   position: absolute;
  //   right: 0;
  //   bottom:0.5em;
  //   width: 540px;
  //   height: 600px;
  //   display: block;
  //   @include mq(sm){
  //     width: 460px;
  //     height: 520px;
  //   }
  //   @include mq(xs){
  //     width: 250px;
  //     height: 280px;
  //   }
  // }
  .c-inner{
    position: relative;
    z-index: 2;
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 1600px;
      padding-bottom:37rem;
    }
    @include mq(sm){
      padding-bottom:20rem;
    }
    @include mq(xs){
      padding-bottom:0rem;
    }
  }
  &__box{
    width: 47%;
    background:#fff;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
    &.n-users{
      transform: translateY(37rem);
    }
    .c-ttl02{
      padding:2.5em;
    }
    &_txt{
      padding: 2em;
    }
    @include mq(sm){
      width: 48%;
      &.n-users{
        transform: translateY(20rem);
      }
      .c-ttl02{
        padding:1.8em;
      }
      &_txt{
        padding: 1.5em;
      }
    }
    @include mq(xs){
      width: 100%;
      &.n-users{
        transform: translateY(0rem);
        margin-bottom: 2em;
      }
    }
  }
  a{
    display: block;
    @include mq(sm-){
      &:hover{
        opacity: 0.6;
      }
    }
  }
  img{
    max-width: 100%;
  }
  &__pad{
    transform: translateY(18rem)!important;
    >div{
      position: absolute;
      img{
        max-width: 100%;
      }
    }
    &_box01{
      width: 45px;
      height: 45px;
      right: 50rem;
      bottom:1rem;
      animation-delay: 0.2s!important;
    }
    &_box02{
      width: 46px;
      height: 46px;
      right: 37rem;
      bottom: 2rem;
      animation-delay: 0.4s!important;
    }
    &_box03{
      width: 51px;
      height: 51px;
      right: 29rem;
      bottom: 10rem;
      animation-delay: 0.6s!important;
    }
    &_box04{
      width: 51px;
      height: 51px;
      right: 31rem;
      bottom: 22rem;
      animation-delay: 0.8s!important;
    }
    &_box05{
      width: 56px;
      height: 56px;
      right: 16rem;
      bottom: 25rem;
      animation-delay: 1.0s!important;
    }
    &_box06{
      width: 61px;
      height: 61px;
      right: 15rem;
      bottom: 41rem;
      animation-delay: 1.2s!important;
    }
    &_box07{
      width: 53px;
      height: 53px;
      right: 2rem;
      bottom: 36rem;
      animation-delay: 1.4s!important;
    }
    &_box08{
      width: 67px;
      height: 67px;
      right: 0;
      bottom:54rem;
      animation-delay: 1.6s!important;
    }
    &.is-anime{
      @keyframes fadein {
        100% {  opacity: 1;}
      }
      .fadein{
        animation: fadein 0.1s ease forwards;
      }
    }
  }
  .fadein{
    transform: translateY(0);
    opacity: 0;
  }
}



.p-idx_cats{
  .c-inner{
    width: 1600px;
    position: relative;
    &:before{
      content: "";
      background: url('/assets/img/common/c-flower.png') no-repeat;
      background-size: contain;
      width: 183px;
      height: 143px;
      display: block;
      position: absolute;
      left: 0;
      top: -23rem;
    }
    @include mq(xs){
      &:before{
        width: 100px;
        height: 80px;
        left: 0;
        top: -14rem;
      }
    }
  }
  .c-ttl01{
    text-align: center;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 3em;
    li{
      width: 23%;
      background: #fff;
      box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
      @include mq(xs-){
        &:nth-child(n+5){
          margin-top: 3.5em;
        }
      }
    }
    &_txt{
      text-align: center;
      padding: 0.7em;
    }
    a{
      display: block;
      .p-idx_cats__list_img{
        overflow: hidden;
        img{
          max-width: 100%;
          transition-duration: 0.3s;
        }
        @include mq(sm-){
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
        }
      }
    }
    @include mq(xs){
      li{
        width: 48%;
        &:nth-child(n+3){
          margin-top: 2em;
        }
      }
    }
  }
}

.p-idx_menu{
  padding: 20rem 0;
  position: relative;
  overflow: hidden;
  @include mq(sm){
    padding: 15rem 0;
  }
  @include mq(xs){
    padding: 10rem 0;
  }
  &:before{
    background:url('/assets/img/common/c-pad.png') no-repeat;
    background-size: contain;
    width: 520px;
    height: 570px;
    left: -12rem;
    top: -15rem;
  }
  .c-inner{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__img{
    position: relative;
    z-index: 2;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.4);
    @include mq(xs-){
      width: 40%;
    }
    img{
      max-width: 100%;
    }
  }
  &__txt{
    position: relative;
    @include mq(xs-){
      width: 60%;
      padding: 6em 0 0 3em;
    }
    &:before{
      content: "";
      background: #fff;
      width: 100vw;
      height: 116%;
      display: block;
      position: absolute;
      left: -6em;
      top:0;
    }
    .inner{
      position: relative;
      p{
        margin-bottom: 3em;
      }
    }
    @include mq(sm){
      padding: 4em 0 0 2em;
    }
    @include mq(xs){
      width: 100%;
      background: #fff;
      padding:2em;
      &:before{
        display: none!important;
      }
      .inner{
        p{
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.p-idx_gallery{
  margin-bottom: 20rem;
  @include mq(sm){
    margin-bottom: 15rem;
  }
  @include mq(xs){
    margin-bottom: 10rem;
  }
  li{
    max-width: 1900px;
    @include mq(sm){
      max-width: 1000px;
    }
    @include mq(xs){
      max-width: 800px;
    }
  }
  img{
    max-width: 100%;
  }
}

.p-idx_about{
  position: relative;
  &__img{
    img{
      max-width: 100%;
    }
  }
  &__txt{
    @include mq(sm-){
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
    }
    text-align: center;
    color: #fff;
  }
  @include mq(sm){
    &__img{
      margin-bottom: 2em;
    }
    &__txt{
      width: 92%;
      margin:auto;
      background: $main_color;
      padding:1.5em;
    }
  }
}

.p-idx_blog{
  padding: 20rem 0;
  @include mq(sm){
    padding: 15rem 0;
  }
  @include mq(xs){
    padding: 10rem 0;
  }
  .c-inner{
    width: 1600px;
    @include mq(xs-){
      display: flex;
      align-items:center;
      justify-content: space-between;
    }
  }
  img{
    max-width: 100%;
  }
  &__ttl{
    color: #fff;
    width: 20%;
    @include mq(sm){
      width: 30%;
      .c-btn01{
        max-width: 19rem;
      }
    }
    @include mq(xs){
      width: 100%;
      .c-ttl01{
        text-align: center;
        margin:0 auto 2em;
      }
    }
  }
  &__post{
    width: 75%;
    display:flex ;
    align-items:center;
    flex-wrap: wrap;
    align-items: stretch;
    @include mq(sm){
      width: 68%;
    }
    @include mq(xs){
      width: 100%;
      margin-bottom: 2em;
    }
    li{
      width: 23%;
      box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
      @include mq(sm-){
        &:nth-child(n+2){
          margin-left: 2.6%;
        }
      }
      @include mq(sm){
        width: 48%;
        &:nth-child(2n){
          margin-left: 2%;
        }
        &:nth-child(n+3){
          margin-top: 1em;
        }
      }
      @include mq(xs){

      }
    }
    a{
      background: #fff;
      display: block;
      height: 100%;
      .p-idx_blog__post_img{
        overflow: hidden;
        img{
          transition-duration: 0.3s;
        }
        transition-duration: 0.3s;
      }
      @include mq(sm-){
        &:hover{
          .p-idx_blog__post_img{
            img{
              transform: scale(1.1);
            }
          }
        }
      }
    }
    &_txt{
      padding:1em;
      line-height: 1;
      word-break: break-all;
      time{
        display: block;
        font-size: 1.4rem;
        margin-bottom: 0.8em;
        @include mq(xs){
          font-size: 1.2rem;
        }
      }
      p{
        line-height: 1.4;
        margin-bottom: 0.8em;
        @include mq(xs){
          font-size: 1.4rem;
        }
      }
      .c-btn02{
        text-align: right;
        @include mq(xs){
          font-size: 1.2rem;
        }
      }
    }
  }
  @include mq(xs){
    .c-btn01{
      margin:auto;
    }
  }
}

.p-idx_shop{
  color: $font-color;
  .c-inner{
    width: 1600px;
    background: #fff;
    padding: 4em 12em;
    @include mq(w1700){
      padding: 4em 5em;
    }
    @include mq(md){
      padding: 3em;
    }
    @include mq(xs){
      padding: 2em 1.5em;
    }
  }
  &__wrapp{
    @include mq(sm-){
      display: flex;
      align-items:center;
      justify-content: space-between;
    }
  }
  &__txt{
    width: 30%;
    text-align: center;
    @include mq(sm){
      width: 100%;
      margin-bottom: 2em;
    }
    >p{
      >span{
        display: block;
        line-height: 1;
        font-size: 1.8rem;
        margin-bottom: 0.5em;
      }
      line-height: 1.8;
      font-size: 1.4rem;
      margin-bottom: 0rem;
    }
    @include mq(xs){
      >p{
        >span{
          font-size: 1.6rem;
        }
        font-size: 1.2rem;
      }
    }
  }
  &_tel{
    display: flex;
    line-height: 1;
    align-items: baseline;
    justify-content: center;
    font-family: $base_font_en;
    margin-bottom: 1em;
    >span{
      display: block;
      font-size: 4rem;
      margin-right: 0.3em;
    }
    p{
      font-size: 9rem;
    }
    @include mq(w1700){
      >span{
        font-size: 3rem;
      }
      p{
        font-size: 8rem;
      }
    }
    @include mq(md){
      p{
        font-size: 6rem;
      }
    }
  }
  &__map{
    width: 60%;
    padding-top: 42.25%;
    @include mq(sm){
      width: 100%;
    }
    @include mq(sm){
      padding-top: 70%;
    }
  }
}