/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1;
}

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  @include mq(md) {
    font-size: .9vw;
  }
  @include mq(sm) {
    font-size: 1.3vw;
  }
  @include mq(xs) {
    font-size: 1.5vw;
  }
  @include mq(ss) {
    font-size: 2.5vw;
  }
}

body {
  font-family: $base_font;
  font-weight: 400;
  font-size: 1.6rem;
  color: $font_color;
  background: $main_color;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased;
  position: relative;
  @include mq(xs) {
    line-height: 1.6;
  }
}
.grecaptcha-badge{
  z-index: 50;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main {
  display: block;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
select,
textarea {
  width: 100%;
  font-size:1.6rem;
  border:solid 1px #cccccc;
  line-height: 1.3;
  display: inline-block;
  padding:.5em .65em;
  &:focus{
    outline:none;
  }
  @include mq(md){
    padding:.75em 1em;
  }
}
::placeholder{
  color:#cccccc;
}
select {
  padding:.75em 1.5em .75em .75em;

  .ios & {
    -webkit-appearance: button;
    appearance: button;

    @include mq(sm) {
    }
  }
}


input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea{
    line-height: 1;
    vertical-align: middle;
    &:fucus{
      outline:none;
      background:#d4ebf7;
    }
}
textarea {
  min-height: 12.5em;

  @include mq(xs) {
    min-height: 10em;
  }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button{
  color: $font_color;
  border: none;
  background: none;
  &:focus{
    outline: none;
  }
}

img {
  vertical-align: middle;
}

table {
  width: 100%;
}

a {
  color: $font_color;
  text-decoration: none;

  &[href=""]{
    // pointer-events: none;
  }
  @include mq(sm-) {
    transition: $transition_val;
  }
}


svg {
  width: 1em;
  height: 1em;
  fill: $font_color;
}


h1,h2,h3,h4,h5,h6,th,dt,strong,b{
  font-weight: 500;
  line-height: 1;
}
