@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/

.l-footer{
  color: #fff;
  text-align: center;
  padding: 6rem 0;
  &_logo{
    margin-bottom: 1em;
  }
  p{
    line-height: 1.6;
    margin-bottom: 2em;
    @include mq(xs){
      font-size: 1.2rem;
    }
  }
  &_sns{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
    li{
      position: relative;
      &:nth-child(n+2){
        margin-left: 1.5em;
      }
    }
    a{
      border:2px solid #fff;
      border-radius: 50%;
      width: 5rem;
      padding-top:5rem;
      display: block;
      svg{
        fill:#fff;
        height: 28px;
        width: 28px;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
      }
      @include mq(sm-){
        &:hover{
          background: #fff;
          svg{
            fill:$main_color;
          }
        }
      }
    }
    @include mq(xs){
      a{
        width: 4rem;
        padding-top:4rem;
      }
    }
  }
  &_navi{
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    li{
      a{
        color: #fff;
        font-size: 1.2rem;
        position: relative;
        &:before{
          content: "";
          background:#fff;
          width: 0%;
          height: 2px;
          display: block;
          position: absolute;
          left: 0;
          bottom:-0.3em;
          transition-duration: 0.3s;
        }
        @include mq(sm-){
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
      &:nth-child(n+2){
        margin-left: 1.3em;
      }
    }
  }
  .copy{
    color: #fff;
  }
}

