@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  $amp :&;

  &__inner{
    display: flex;
    justify-content: space-between;
    align-items:center;
    max-width: 92%;
    margin: auto;
  }
  &__logo{
    p{
     font-size: 1rem;
     color: #fff;
    }
    a{
      display: block;
      @include mq(sm-){
        &:hover{
          opacity: 0.6;
        }
      }
      img{
        max-width: 220px;
        width: 100%;
      }
    }
  }

  &__toggle{
    position: relative;
    z-index: 102;
    width: 5rem;
    height: 5rem;
    .so_toggle{
      position: absolute;
      background: transparent;
      width: 72%;
      height: 72%;
      padding:0;
      left:50%;
      top:50%;
      transform:translate(-50%,-50%);
      .hamburger-icon{
        background: transparent;
        top:0;
        &:before,&:after{
          top:0;
        }
        &:before{
          transform:translateY(-4px);
        }
        &:after{
          transform:translateY(4px);
        }
      }
    }
  }
  .slideout-open & {
    &__toggle{
      background: rgba(255,255,255,0);
      .so_toggle{
        .hamburger-icon{
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
    }
  }
  @include mq(sm){
    padding:2em 0;
    &__inner{
      align-items:center;
    }
    &__menu{
      width: 50%;
    }
  }
  @include mq(xs){
    padding:1em 0;
    &__logo{
      width: 50%;
    }
    &__menu{
      width: 0%;
    }
  }
}

.-under .l-header{
  position: relative;
}