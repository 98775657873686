@charset "utf-8";
/*============================================

  OTHER PAGE

============================================*/
.p-cat{
  .c-ttl01{
    color: #fff;
  }
  &_list{
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(xs){
      padding-top:8rem;
    }
    &__box{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 30%;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.6);
        @include mq(xs-){
          margin-right: 5%;
          &:nth-child(3n){
            margin-right: 0;
          }
          &:nth-child(n+4){
            margin-top: 3em;
          }
        }
        >a{
          display: block;
          background: #fff;
          height: 100%;
          span{
            display: block;
            text-align: center;
            padding:0.8em;
          }
          img{
            max-width: 100%;
            transition-duration: 0.6s;
          }
          @include mq(sm-){
            &:hover{
              img{
                opacity: 0.6;
              }
            }
          }
        }
      }
      @include mq(xs){
        justify-content: space-between;
        li{
          width: 48%;
          &:nth-child(n+3){
            margin-top: 1em;
          }
          a{
            font-size: 1.4rem;
          }
        }
      }
    }
  
  }
}


.modal{
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  img{
    max-width: 320px;
    width: 100%;
    @include mq(sm){
      margin-right: 1em;
    }
    @include mq(xs){
      margin-right: 0em;
      margin-bottom: 1.5em;
    }
  }
  &__bg{
    background: rgba(0,0,0,0.8);
    height: 100vh;
    position: absolute;
    width: 100%;
  }
  &__content{
    background: #fff;
    left: 50%;
    padding: 5em 4em;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 1000px;
    width: 92%;
    z-index: 201;
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      text-align: left;
      align-items:center;
    }
    .ttl{
      width: 55%;
    }
    span{
      font-size: 3rem;
      display: block;
      position: relative;
      &:before{
        @include icon('pad2');
        color: $main_color;
        margin-right: 0.3em;
      }
    }
    p{
      margin-bottom: 1.5em;
    }
    em{
      font-style: initial;
    }
    &:before,
    &:after{
      content: "";
      background: url('/assets/img/common/c-line.png') repeat-x;
      background-size: contain;
      width: 100%;
      height: 52px;
      display: block;
      position: absolute;
      left: 0;
      @include mq(sm){
        height: 30px;
      }
    }
    &:before{
      top:0;
    }
    &:after{
      bottom:0;
    }
    @include mq(xs){
      padding:4em 1.5em 3em;
      .ttl{
        width: 100%;
      }
    }
  }
}
.close {
  z-index: 20;
  position: absolute;
  top: 1.5em;
  right: 0.5em;
  color: $main_color;
  font-size: 2em;
  svg{
    fill:$main_color;
  }
  @include mq(xs){
    top: 0.5em;
    right: 0.3em;
  }
}

    

/*-------------------------------
 blog
-------------------------------*/
.p-blog{
  .c-navi{
    margin-bottom: 0rem;
    justify-content: flex-start;
    li{
      width: auto;
      min-width: 280px;
      margin-right: 1em;
      margin-bottom: 0.5em;
      margin-top: 0;
    }
    @include mq(sm){
      li{
        min-width: 200px;
      }
    }
    @include mq(xs){
      justify-content: space-between;
      li{
        margin-right: 0;
        min-width: 48%;
        a{
          font-size: 1.4rem;
        }
      }
    }
  }
  &_list{
    padding-top:6rem;
    @include mq(sm){
      padding-top:6rem;
    }
    @include mq(xs){
      padding-top:4rem;
    }
    @include mq(xs-){
      display: flex;
      flex-wrap: wrap;
      align-items:stretch;
    }
    li{
      width: 30%;
      margin-right: 5%;
      @include mq(xs-){
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n+4){
          margin-top: 2em;
        }
      }
      a{
        background: #fff;
        display: block;
        height: 100%;
        position: relative;
        .c-btn02{
          text-align: right;
          position: absolute;
          right: 2em;
          bottom:1em;
          &:after{
            top: 38%;
          }
          &:before,
          &:after{
            right: -8%;
          }
        }
        @include mq(sm-){
          &:hover{
            .c-btn02{
              &:before,
              &:after{
                right: -15%;
              }
            }
            .p-blog_list__img{
              img{
                transform: scale(1.1);
              }
            }
          }
        }
      }
      @include mq(xs){
        width: 100%;
        &:nth-child(n+2){
          margin-top: 2em;
        }
      }
    }
    &__img{
      overflow: hidden;
      img{
        max-width: 100%;
        transition-duration: 0.3s;
      }
    }
    &__txt{
      padding:1.5em 1.5em 4em 1.5em;
      time{
        font-size: 1.4rem;
        color: #747474;
        &:before{
          @include icon('pad');
          margin-right: 0.5em;
          color: $main_color;
        }
      }
      p{
        display: block;
        word-break: break-all;
        line-height: 1.6;
      }
    }
  }
  &_single{
    &_sec{
      padding-top:12rem;
      @include mq(sm){
        padding-top:10rem;
      }
      @include mq(xs){
        padding-top:8rem;
      }
      >.c-inner{
        position: relative;
        &:before{
          content: "";
          background:url('/assets/img/common/c-flower.png') no-repeat;
          background-size: contain;
          width: 183px;
          height: 143px;
          display: block;
          position: absolute;
          right: 0;
          top:-3em;
        }
        @include mq(sm){
          &:before{
            width: 131px;
            height: 101px;
          }
        }
        @include mq(xs){
          &:before{
            width: 107px;
            height: 80px;
          }
        }
      }
    }
    &__box{
      background: #fff;
      padding: 4em;
      @include mq(sm){
        padding: 3em;
      }
      @include mq(xs){
        padding: 2em;
      }
    }
    &__head{
      border-bottom:1px dashed #ddd;
      padding-bottom: 2em;
      margin-bottom: 2em;
      time{
        font-size: 1.4rem;
        margin-bottom: 0.5em;
        display: block;
      }
      .title{
        font-size: 2.4rem;
      }
      @include mq(xs){
        .title{
          font-size: 2rem;
        }
      }
    }
    &__body{
      margin-bottom: 3em;
    }
  }
}

/*-------------------------------
 privacy
-------------------------------*/

.p-privacy{
  &_list{
    .read{
      margin-bottom: 3em;
    }
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(sm){
      padding-top:8rem;
    }
    .c-inner{
      background: #fff;
      padding: 4em;
      @include mq(sm){
        padding: 3em;
      }
      @include mq(sm){
        padding: 2em;
        font-size: 1.4rem;
      }
    }
    dt{
      margin-bottom: 1em;
      &:before{
        @include icon('pad');
        margin-right: 0.3em;
        font-size: 1.2em;
      }
    }
    dd{
      margin-bottom: 2em;
      padding-bottom:2em;
      border-bottom: 1px dashed #ddd;
      span{
        display: block;
        margin-top: 1em;
      }
      a{
        word-break:break-all;
      }
    }
  }
}

/*-------------------------------
menu
-------------------------------*/
.p-menu{
  &_list{
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(sm){
      padding-top:8rem;
    }
    img{
      max-width: 100%;
    }
  }
}

/*-------------------------------
price
-------------------------------*/
.p-shop{
  &_greeting{
    padding-bottom:10rem;
    @include mq(sm){
      padding:8rem 0;
    }
    .c-inner{
      width: 100%;
      max-width: 100%;
      img{
        width: 100%;
      }
    }
    &__img{
      @include mq(xs){
        margin-bottom: 1em;
      }
    }
    &__txt{
      padding:4em;
      width: 1200px;
      margin: auto;
      background: #fff;
      transform: translateY(-20%);
      span{
        display: block;
        lighting-color: 1;
        font-size:2.2rem;
        text-align: center;
        margin-bottom: 0.5em;
      }
      p{
        line-height: 2;
        @include mq(xs-){
          text-align: center;
        }
      }
      @include mq(sm){
        padding:3em;
        width: 92%;
        margin:auto;
        transform: translateY(-5%);
      }
      @include mq(xs){
        padding:2em;
        transform: translateY(0%);
        >span{
          font-size:1.6rem;
          text-align: left;
        }
        p{
          font-size: 1.4rem;
          line-height: 1.8;
        }
      }
    }
  }
  &_ptclr{
    overflow: hidden;
    padding-bottom:12rem;
    @include mq(sm){
      padding-bottom:10rem;
    }
    @include mq(xs){
      padding-bottom:8rem;
    }
    .c-inner{
      @include mq(xs-){
        display: flex;
        align-items:center;
      }
    }
    &__img{
      width: 50%;
      position: relative;
      z-index: 2;
      margin-bottom: 1em;
      img{
        width: 100%;
      }
      @include mq(xs){
        width: 100%;
      }
    }
    &__txt{
      width: 50%;
      position: relative;
      padding:4em;
      @include mq(xs-){
        &:before{
          content: "";
          background: #fff;
          display: block;
          width: 100vw;
          height: 100%;
          position: absolute;
          left: -4em;
          top:0;
        }
      }
      .inner{
        position: relative;
      }
      @include mq(sm){
        padding:3em;
      }
      @include mq(xs){
        padding:2em;
        width: 100%;
        background: #fff;
      }
    }
  }
  &_gallery{
    text-align: center;
    .c-inner{
      width: 1600px;
    }
    &__list{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 33.3%;
        img{
          width: 100%;
        }
      }
      @include mq(xs){
        justify-content: space-between;
        li{
          width: 49%;
          &:nth-child(n+3){
            margin-top: 0.5em;
          }
        }
      }
    }
  }
  &_ovv{
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(xs){
      padding-top:8rem;
    }
    .c-inner{
      background: #fff;
      padding:4em;
      @include mq(xs){
        padding:2em;
      }
    }
    .c-tbl{
      font-size: 1.4rem;
    }
  }
}


/*-------------------------------
price
-------------------------------*/
.p-price_snack{
  margin-top: 3em;
  .c-inner{
    background: #fff;
    padding:4em;
    @include mq(sm){
      padding:3em;
    }
    @include mq(xs){
      padding:2em;
    }
  }
  &__wrap{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
    &_box{
      width: 42%;
      img{
        max-width: 100%;
      }
      .txt{
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
      }
      @include mq(xs){
        width: 100%;
        &:nth-child(n+2){
          margin-top: 2em;
        }
      }
    }
  }
}


/*-------------------------------
beginner_
-------------------------------*/
.p-beginner_box{
  padding: 15rem 0 0;
  @include mq(sm){
    padding: 10rem 0 0;
  }
  @include mq(xs){
    padding: 8rem 0 0;
  }
}



/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound{
  text-align: center;

  &_main{
    text-align: center;
    padding-top:12rem;
    @include mq(sm){
      padding-top:10rem;
    }
    @include mq(xs){
      padding-top:8rem;
    }
   .c-inner{
      background: #fff;
      padding: 4em;
      @include mq(sm){
        padding: 3em;
      }
      @include mq(xs){
        padding: 2em;
      }
    }
    p{
      margin-bottom: 3em;
    }
  }
  .c-btnwrap{
    margin-top: $margin_md;
  }
  @include mq(ss){
    text-align: center;
  }
}