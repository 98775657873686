@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  &__inner{
    @include mq(sm-){
      display: flex;
      align-items: center;
    }
    padding: 2em 0;
  }
  &__menu{
    @include mq(sm-){
      display: flex;
      align-items: flex-start;
    }
    li{
      @include mq(sm-){
        &:nth-child(n+2){
          margin-left: 2.2em;
        }
      }
      a{
        display: inline-block;
        color: #fff;
        position: relative;
        padding-left:1.3em;
        &:before{
          @include icon('pad');
          position: absolute;
          left: 0;
          top:0;
          transition-duration: 0.3s;
          color:$sub_color;
          opacity: 0;
        }
        @include mq(sm-){
          &:hover{
            &:before{
              opacity: 1;
            }
          }
        }
      }
      @include mq(md){
        font-size: 1.3rem;
        a{
          padding-left:1em;
        }
      }
    }
  }

  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: initial;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
  }
  @include mq(sm){
    background: rgba(0,0,0,.8);
    padding: 8rem 3rem 5rem;
    right: 0;
    transform:translateX(100%);
    width: 100%;
    &__inner{
      max-width: 100%;
    }
    .slideout-open &{
      transform:translateX(0);
      width: 70%;
    }
    &__menu{
      margin-bottom: 2em;
      li{
        border-bottom:dashed 1px #fff;
        a{
          padding: 0.7em 0;
          display: block;
          span{
            &:before{
              @include icon('pad');
              margin-right: 0.3em;
            }
          }
        }
      }
    }


  }
}
