@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tqj965');
  src:  url('../fonts/icomoon.eot?tqj965#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tqj965') format('truetype'),
    url('../fonts/icomoon.woff?tqj965') format('woff'),
    url('../fonts/icomoon.svg?tqj965#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

@if $type == 'global' {
  content: "\e92b";
}
@else if $type == 'home' {
  content: "\e92c";
}
@else if $type == 'sphere' {
  content: "\e92d";
}
@else if $type == 'earth' {
  content: "\e92e";
}
@else if $type == 'info' {
  content: "\e92f";
}
@else if $type == 'info11' {
  content: "\e930";
}
@else if $type == 'cog' {
  content: "\e931";
}
@else if $type == 'map' {
  content: "\e932";
}
@else if $type == 'step' {
  content: "\e933";
}
@else if $type == 'track' {
  content: "\e934";
}
@else if $type == 'train' {
  content: "\e935";
}
@else if $type == 'clock' {
  content: "\e936";
}
@else if $type == 'calendar' {
  content: "\e937";
}
@else if $type == 'cart' {
  content: "\e938";
}
@else if $type == 'user' {
  content: "\e939";
}
@else if $type == 'enter' {
  content: "\e93a";
}
@else if $type == 'exit' {
  content: "\e93b";
}
@else if $type == 'dl' {
  content: "\e93c";
}
@else if $type == 'note' {
  content: "\e93d";
}
@else if $type == 'document' {
  content: "\e93e";
}
@else if $type == 'file-excel' {
  content: "\e93f";
}
@else if $type == 'file-word' {
  content: "\e940";
}
@else if $type == 'pdf' {
  content: "\e941";
}
@else if $type == 'zip' {
  content: "\e943";
}
@else if $type == 'window' {
  content: "\e944";
}
@else if $type == 'search' {
  content: "\f003";
}
@else if $type == 'free' {
  content: "\e945";
}
@else if $type == 'tel' {
  content: "\e946";
}
@else if $type == 'mobile' {
  content: "\e947";
}
@else if $type == 'mail' {
  content: "\e948";
}
@else if $type == 'mail11' {
  content: "\e949";
}
@else if $type == 'bars' {
  content: "\e94a";
}
@else if $type == 'close' {
  content: "\ea0e";
}
@else if $type == 'checkmark' {
  content: "\e94b";
}
@else if $type == 'arrow' {
  content: "\e94c";
}
@else if $type == 'arrow02' {
  content: "\e94d";
}
@else if $type == 'chevron-left' {
  content: "\f055";
}
@else if $type == 'chevron-right' {
  content: "\f056";
}
@else if $type == 'chevron-up' {
  content: "\f079";
}
@else if $type == 'chevron-down' {
  content: "\f07a";
}
@else if $type == 'twitter' {
  content: "\f09b";
}
@else if $type == 'facebook' {
  content: "\f09c";
}
@else if $type == 'facebook2' {
  content: "\e94e";
}
@else if $type == 'instagram' {
  content: "\f16e";
}
@else if $type == 'line' {
  content: "\e94f";
}
@else if $type == 'line11' {
  content: "\e950";
}
@else if $type == 'youtube' {
  content: "\e951";
}
@else if $type == 'youtube2' {
  content: "\e952";
}
@else if $type == 'darrow-left' {
  content: "\f108";
}
@else if $type == 'darrow-right' {
  content: "\f109";
}
@else if $type == 'darrow-top' {
  content: "\f10a";
}
@else if $type == 'darrow-bottom' {
  content: "\f10b";
}
@else if $type == 'arrow-left' {
  content: "\f10c";
}
@else if $type == 'arrow-right' {
  content: "\f10d";
}
@else if $type == 'arrow-top' {
  content: "\f10e";
}
@else if $type == 'arrow-bottom' {
  content: "\f10f";
}
@else if $type == 'circle-up' {
  content: "\ea46";
}
@else if $type == 'circle-right' {
  content: "\ea47";
}
@else if $type == 'circle-left' {
  content: "\ea48";
}
@else if $type == 'circle-down' {
  content: "\ea49";
}
@else if $type == 'caret-left' {
  content: "\e953";
}
@else if $type == 'caret-down' {
  content: "\e954";
}
@else if $type == 'caret-right' {
  content: "\e955";
}
@else if $type == 'caret-up' {
  content: "\e956";
}
@else if $type == 'phone' {
  content: "\e942";
}
@else if $type == 'circle-right1' {
  content: "\ea45";
}
@else if $type == 'mail2' {
  content: "\e92a";
}
@else if $type == 'email' {
  content: "\e92a";
}
@else if $type == 'letter' {
  content: "\e92a";
}
@else if $type == 'envelope' {
  content: "\e92a";
}
@else if $type == 'contact' {
  content: "\e92a";
}
@else if $type == 'pad' {
  content: "\e900";
}
@else if $type == 'pad2' {
  content: "\e901";
}


}
