@charset "utf-8";
/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
article{
  background:url('/assets/img/common/c-bg.jpg');
  position: relative;
}
.-under{
  article{
    &:before,
    &:after{
      content: "";
      background:url('/assets/img/common/c-pad.png') no-repeat;
      background-size:cover;
      position: absolute;
      width: 540px;
      height: 600px;
      display: block;
      @include mq(sm){
        width: 460px;
        height: 520px;
      }
      @include mq(xs){
        width: 250px;
        height: 280px;
      }
    }
    &:before{
      left: 0;
      top:20%;
      @include mq(sm){
        top:0%;
      }
    }
    &:after{
      right: 0;
      bottom:0.5em;
    }
    section{
      position: relative;
      z-index: 2;
    }
  }
}

.l-content{
  padding-bottom:15rem;
  @include mq(sm){
    padding-bottom:10rem;
  }
  @include mq(xs){
    padding-bottom:8rem;
  }
}


.l-breadcrumb{
  line-height: 1;
  font-size: 1.4rem;
  background: $main_color;
  position: relative;
  z-index: 2;
  &__list{
    justify-content: flex-start;
    align-items: center;
    padding: 0.8em 0;
    li{
      position: relative;
      padding-right: 1.8125em;
      margin-right: .5625em;
      &:after{
        position: absolute;
        content:"";
        right: 0;
        top:20%;
        @include icon('pad');
        color: #fff;
        width: 7px;
        height: 7px;
        display: block;
      }
      a{
        position: relative;
        display: block;
        padding: 0.5em 0 0.5em 0.3em;
        max-width: 30em;
        text-overflow:ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1.2rem;
        color: #fff;
        &:after{
          position: absolute;
          content:"";
          right: 0;
          left: initial;
          bottom:0;
          width: 0%;
          height: 1px;
          background: #fff;
          transition:$transition_val;
        }
        &:hover{
          &:after{
            left: 0;
            right: initial;
            width: 100%;
          }
        }
      }
      &:last-child{
        padding-right:0;
        margin-right: 0;
        font-weight: 500;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
          &:after{
            display: none;
          }
        }
      }
    }
  }
  @include mq(sm){
    &__list{
      padding:0;
      flex-wrap: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      li{
        a{
          span{
            word-break: keep-all;
            white-space: nowrap;
          }
        }
      }
    }
  }
}